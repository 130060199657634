import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Container, Header, Button } from 'semantic-ui-react'
import rollbar from '../../rollbar'

const ErrorMessageWrap = styled.div`
  margin: 0 auto;
  margin-top: 150px;
  width: 50%;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #9f3a38;
`

/* eslint-disable react/prop-types */
class ErrorHandler extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
    }
  }

  reset = () => {
    this.setState({ error: null })
  }

  componentDidCatch(error) {
    this.setState({
      error,
    })

    rollbar.error('Error while rendering components', error)
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorMessageWrap>
          <Container textAlign="center">
            <Header color="red">Oops!</Header>
            <p>Мы пытались нарисовать красивую страницу, но что-то пошло не так.</p>
            <p>
              При повторении ошибки, пожалуйста, обратитесь за помощью к администратору сервиса.
            </p>
            <Button as={Link} to="/" content="На главную" onClick={this.reset} />
          </Container>
        </ErrorMessageWrap>
      )
    }

    return this.props.children
  }
}

export default ErrorHandler
