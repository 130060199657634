import deepGet from 'lodash/get'
import deepSet from 'lodash/set'

const setPathValue = (obj, propPath, val) => {
  const pathValue = deepGet(obj, propPath)
  let newValue = val

  if (pathValue && Array.isArray(pathValue)) {
    newValue = [...pathValue, val]
  } else if (pathValue) {
    newValue = [pathValue, val]
  }

  deepSet(obj, propPath, newValue)
}

export default setPathValue
