import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Container, Header, Button } from 'semantic-ui-react'

const Page404Wrap = styled.div`
  margin-top: 150px;
  font-size: 16px;
  line-height: 16px;

  span {
    font-weight: bold;
  }

  .ui.header {
    font-size: 32px;
    line-height: 40px;
  }
`

const Page404 = () => (
  <Page404Wrap>
    <Container textAlign="center">
      <Header>
        Oops!
        <br />
        This is awkward.
      </Header>
      <p>
        We encountered a <span>404 Not Found</span> error.
      </p>
      <p>You are looking for something that doesn&apos;t exist or may have moved.</p>
      <Button as={Link} to="/" content="Home" />
    </Container>
  </Page404Wrap>
)

export default Page404
